import { api } from '@apis/hc';

export const getAddressByTerm = async ({
  queryKey,
}: {
  queryKey: [string, string];
}): Promise<ExploreSearchData[]> => {
  const [, term] = queryKey;

  const res = await api.search.address.by.term.$get({
    query: {
      query: term,
    },
  });

  const data = (await res.json()) as any;


  const result: ExploreSearchData[] = data.addresses.map((address: any) => {
    const { jibunAddress = '', roadAddress = '', x, y, distance = 0 } = address;
    return {
      jibunAddress,
      roadAddress,
      latitude: Number(y),
      longitude: Number(x),
      distance: Number(distance),
    };
  });

  return result;
};
