import Head from 'next/head';

export const ApplicationMetaTags = ({ academy }: { academy: Academy }) => {
  const isSimulation = academy.type === 'simulation';

  const academyName = isSimulation ? academy.name : academy.name + ' 운전면허학원';

  return (
    <Head>
      {academy.state === '비입점' && <meta name="robots" content="noindex" key="robots" />}
      <title key="title">{`${academyName} 실시간 온라인 예약 | 운전선생`}</title>
      <meta
        key="meta-title"
        name="title"
        content={`${academyName} 실시간 온라인 예약 | 운전선생`}
      />
      <meta
        key="meta-og-title"
        property="og:title"
        content={`${academy.displayName} 실시간 온라인 예약 | 운전선생`}
      />
      <meta
        key="meta-description"
        name="description"
        content={`${academy.displayName}을 운전선생에서 최저가로 예약해보세요. 1종 보통, 2종 자동 등 운전면허 취득에 필요한 정보를 확인하세요.`}
      />
      <meta
        key="meta-og-description"
        name="og:description"
        content={`${academy.displayName}을 운전선생에서 최저가로 예약해보세요. 1종 보통, 2종 자동 등 운전면허 취득에 필요한 정보를 확인하세요.`}
      />
    </Head>
  );
};
