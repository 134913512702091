import { AppBar } from 'design-system';
import { useScroll } from 'framer-motion';
import { cn } from 'tailwind-config';

import { scrollContainerRef } from '@components/Layout/Layout';

import { TOP_NAV_BAR_HEIGHT } from '@constants';
import { WEB_TO_APP_LINK } from '@constants/links';
import { isAppApproaching } from '@utils/Common';

import { GlobalAppBarItem } from './components';
import { useGetAppBarProps } from './hooks';

/**
 * 전역적으로 사용되는 AppBar 입니다.
 * AppBar : DS의 AppBar. 디자인 요소만 존재합니다.
 * GlobalAppBar : AppBar를 사용하는 컴포넌트. 디자인 요소와 로직이 존재합니다.
 * LocalAppBar (이름은 각 상황에 맞게 조절) : AppBar를 사용하는 컴포넌트. 디자인 요소와 로직이 존재합니다. 상황에 따라 GlobalAppBar를 사용할 수 없을 경우 LocalAppBar를 정의해서 사용합니다.
 */
export const GlobalAppBar = ({ className }: { className?: string }) => {
  const {
    leftItem,
    centerItem,
    type,
    rightItems = [],
    text,
    offNavbar,
    isFixed,
    showProgressOnScroll = false,
    backgroundColor,
    backRedirectUrl,
    showWebToAppBanner,
  } = useGetAppBarProps();

  const { scrollYProgress } = useScroll({
    container: scrollContainerRef,
    layoutEffect: false,
  });

  if (offNavbar) return null;

  const isAppUser = isAppApproaching();

  const showWebToAppBannerState = showWebToAppBanner && !isAppUser;

  return (
    <div className={cn('relative', className)}>
      {/* {showWebToAppBannerState && <PCWebToAppBanner WebToAppLink={'https://abr.ge/iwzgca'} />} */}
      <AppBar
        className={cn(
          (isFixed || backgroundColor === 'transparent') && 'fixed  z-[100]',
          ' top-0 ',
        )}
        style={{
          backgroundColor,
        }}
        type={type ?? 'title-center'}
        leftItem={<GlobalAppBarItem item={leftItem} backRedirectUrl={backRedirectUrl} />}
        rightItem={rightItems.map((item, index) => (
          <GlobalAppBarItem key={item + index.toString()} item={item} />
        ))}
        centerItem={<GlobalAppBarItem item={centerItem} />}
        text={text}
        progressBarOption={{
          showProgressBar: showProgressOnScroll,
          progressPercentage: scrollYProgress,
        }}
        showWebToAppBanner={showWebToAppBanner}
        isApp={isAppUser}
        WebToAppLink={WEB_TO_APP_LINK}
      />
      {isFixed && (
        <div
          style={{
            height: TOP_NAV_BAR_HEIGHT,
            backgroundColor,
          }}
        />
      )}
    </div>
  );
};
