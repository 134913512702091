import { useMemo } from 'react';

import { Academy } from '@db/collections';
import { filterStateAtom } from '@jotaiStore';
import { useQuery } from '@tanstack/react-query';
import { hc as honoClient } from 'hono/client';
import { useAtom } from 'jotai';
import SuperJSON from 'superjson';

import { AppType } from '@pages/api/v2/[[...route]]';

import { useAcademyType } from '../use-academy-type';
import { useGetFilteredAcademies } from './use-get-filtered-academies';

const api = honoClient<AppType>('/').api.v2;
export const getMapAcademies = async ({
  lessonCode,
  academyType,
  isOnlyBookableFilter,
}: {
  lessonCode: LessonCode;
  academyType: 'simulation' | 'academy';
  isOnlyBookableFilter: boolean;
}) => {
  const result = await api.explore['map-academies'].$get({
    query: { lessonCode, isBookable: String(isOnlyBookableFilter), academyType },
  });

  return await result.json();
};

export const getListAcademies = async ({
  lessonCode,
  academyType,
  isOnlyBookableFilter,
}: {
  lessonCode: LessonCode;
  academyType: 'simulation' | 'academy';
  isOnlyBookableFilter: boolean;
}) => {
  const result = await api.explore['list-academies'].$get({
    query: { lessonCode, isBookable: String(isOnlyBookableFilter), academyType },
  });

  return (SuperJSON.parse((await result.json()).data as string) as AcademyData[]).map((a) => {
    return new Academy(a);
  });
};

export type ExploreMapAcademy = Awaited<ReturnType<typeof getMapAcademies>>[number];

export const useGetExploreAcademies = <T extends ExploreViewType>(mode: T) => {
  const academyType = useAcademyType();
  const [
    {
      lesson: { value: lessonCode },
      isOnlyBookable: { value: isOnlyBookableFilter },
    },
  ] = useAtom(filterStateAtom);

  const { data: exploreAcademies = [], isLoading } = useQuery<ExploreMapAcademy[] | Academy[]>({
    queryKey: ['ExploreAcademies', mode, lessonCode, academyType, isOnlyBookableFilter],
    queryFn: async ({ queryKey }) => {
      const [, mode, lessonCode, academyType, isOnlyBookableFilter] = queryKey as [
        string,
        T,
        LessonCode,
        'simulation' | 'academy',
        boolean,
      ];

      if (mode === 'map') {
        return await getMapAcademies({
          lessonCode,
          academyType,
          isOnlyBookableFilter,
        });
      }

      if (mode === 'list') {
        return await getListAcademies({
          lessonCode,
          academyType,
          isOnlyBookableFilter,
        });
      }

      return [];
    },
  });

  const firstComeConditionApplidedAcademies = useMemo(() => {
    return (
      mode === 'map'
        ? exploreAcademies.map((a) => ({
            ...a,
            canUseFirstComeCoupon: false,
            // user && user.orders.length > 0 ? false : a.canUseFirstComeCoupon,
          }))
        : exploreAcademies
    ) as T extends 'map' ? ExploreMapAcademy[] : Academy[];
  }, [mode, exploreAcademies]);

  const result = useGetFilteredAcademies(firstComeConditionApplidedAcademies);

  return {
    ...result,
    isLoading: isLoading || result.isLoading,
  };
};
