import { useEffect } from 'react';

import { mapCenterMarkerLocationAtom } from '@jotaiStore';
import { useResetAtom } from 'jotai/utils';
import { replaceCurrentURLState } from 'shared-values';

import { useGetTargetLocation } from './use-get-target-location';

export const useSetLatLngParamsOnAccessExploreRoute = ({
  isFromTeamEvent,
}: {
  isFromTeamEvent: boolean;
  init: boolean;
}) => {
  const resetMapLocation = useResetAtom(mapCenterMarkerLocationAtom);

  const targetLocation = useGetTargetLocation();

  /**
   * 주소 바꿔주는 작업, isFromTeamEvent은 팀 이벤트에서는 주소 변경 x
   */
  useEffect(() => {
    if (!targetLocation || isFromTeamEvent) return;

    const { latitude, longitude } = targetLocation;

    replaceCurrentURLState({ lat: latitude.toString(), lng: longitude.toString() });
  }, [targetLocation, isFromTeamEvent]);

  /**
   * clean-up
   */
  useEffect(() => {
    return () => {
      resetMapLocation();
    };
  }, []);
};
