import { QUERY_KEY } from '@db/constants';
import { useQuery } from '@tanstack/react-query';
import { db } from 'firebase-config';
import { collection, getDocs, query, where } from 'firebase/firestore';

import { AcademyGeoJson } from '../models';

export const fetchAllAcademyGeoJson = async () => {
  const q = query(collection(db, 'AcademyGeoJson'), where('geoJson', '!=', []));

  const querySnapshot = await getDocs(q);

  const data = querySnapshot.docs.map(
    (doc) =>
      new AcademyGeoJson({
        ...(doc.data() as AcademyGeoJson),
        id: doc.id,
      }),
  );

  return data;
};

export const useGetAllAcademyGeoJson = () => {
  return useQuery({
    queryKey: [QUERY_KEY.ALL_ACADEMY_GEOJSON],
    queryFn: fetchAllAcademyGeoJson,
  });
};
