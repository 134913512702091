import { LessonProductType } from '@models/advertisement-data';
import { replaceLessonCode } from 'shared-values/src/utils/academy/replace-lesson-code';

export const useMatchProductWithAcademyProductList = (
  productList: LessonProductType[],
  personalLessonType: LessonCode | null | 'TRAINING' | 'TWO_SMALL',
) => {
  const TWO_AUTO_NEW_LESSON = productList.find(
    (product) => replaceLessonCode(product.code) === 'TWO_AUTO_NEW',
  );

  if (personalLessonType?.includes('TRAINING')) {
    const trainingLessons = productList.filter((product) =>
      replaceLessonCode(product.code).includes('TRAINING'),
    );

    if (!trainingLessons) return productList[0];

    return (
      trainingLessons.find((product) => {
        return replaceLessonCode(product.code) === personalLessonType;
      }) ||
      trainingLessons[0] ||
      TWO_AUTO_NEW_LESSON
    );
  }

  if (personalLessonType?.includes('TWO_SMALL')) {
    const twoSmallLessons = productList.filter((product) =>
      replaceLessonCode(product.code).includes('TWO_SMALL'),
    );

    if (!twoSmallLessons) return productList[0];

    return (
      twoSmallLessons.find((product) => {
        return replaceLessonCode(product.code) === personalLessonType;
      }) || TWO_AUTO_NEW_LESSON
    );
  }

  return (
    productList.find((product) => replaceLessonCode(product.code) === personalLessonType) ||
    TWO_AUTO_NEW_LESSON
  );
};
