import { useMemo } from 'react';

import { BaseLessonCode } from '@db/collections';
import { DisplayAcademy } from '@models';
import { useQuery } from '@tanstack/react-query';
import { NewIcon, Spacer } from 'design-system';

import useGetAdvertisementLocation from '@components/Advertisement/get-advertisement-location';
import ToolTip from '@components/ToolTip';
import { useGetFilteredAcademies } from '@templates/Explore/Explore/hooks';
import { getListAcademies } from '@templates/Explore/Explore/hooks/use-get-explore-academies/use-get-explore-academies';
import { sortByDistance } from '@templates/Explore/Explore/screens/ExploreList/actions/use-get-infinite-academies';

import { getMapAddressData } from '@apis/auth/get-map-address';

import { DEFAULT_LOCATION } from '@constants';
import { useGetLicenseByFunnel } from '@hooks/use-get-license-by-funnel';

import AcademyList from './AcademyList';

export const useGetHomeToAcademyData = ({
  lessonCode,
  academyType,
  isOnlyBookableFilter,
  latitude,
  longitude,
}: {
  lessonCode: LessonCode;
  academyType: 'academy' | 'simulation';
  isOnlyBookableFilter: boolean;
  latitude: number;
  longitude: number;
}) => {
  const { data } = useQuery({
    queryKey: ['homeToAcademy', lessonCode, academyType, isOnlyBookableFilter, latitude, longitude],
    queryFn: async () => await getListAcademies({ lessonCode, academyType, isOnlyBookableFilter }),
  });
  const result = useGetFilteredAcademies(data || [], {
    latitude,
    longitude,
  });

  return result;
};

const HomeToAcademy = () => {
  const userPersonalLicense = useGetLicenseByFunnel();

  const lessonCode: LessonCode | undefined | null =
    userPersonalLicense?.lessonType || 'TWO_AUTO_NEW';

  const latestLocation = useGetAdvertisementLocation();

  const { lat: latitude, lng: longitude } = latestLocation || {
    lat: DEFAULT_LOCATION.latitude,
    lng: DEFAULT_LOCATION.longitude,
  };

  const { data: address, isLoading: isAddressLoading } = useQuery({
    queryKey: ['location', latitude, longitude],
    queryFn: () =>
      getMapAddressData({
        latitude,
        longitude,
      }),
    enabled: latitude !== 0 && longitude !== 0,
  });

  const { academies } = useGetHomeToAcademyData({
    lessonCode,
    academyType: 'academy',
    isOnlyBookableFilter: true,
    latitude,
    longitude,
  });

  const displayAcademies = useMemo(() => {
    console;
    if (!academies) return [];
    if (!latitude || !longitude) return [];
    if (!lessonCode) return [];

    const academiesData = academies
      .filter((academy: { lessons: any[] }) =>
        academy.lessons.find((lesson: { code: string }) => lesson.code === lessonCode),
      )
      .map((a: any) => new DisplayAcademy({ academy: a, lessonCode }));

    return sortByDistance(academiesData, {
      latitude,
      longitude,
    }).slice(0, 3);
  }, [academies, latitude, longitude, lessonCode]);

  return (
    <>
      <div className="flex flex-col px-16 py-32">
        <div className=" flex justify-between">
          <div className="text-new-Section-Title text-new-gray-900">
            가까운{' '}
            {new BaseLessonCode(userPersonalLicense?.lessonType || 'TWO_AUTO_NEW').lessonText} 학원
            🔍
          </div>
          <ToolTip
            toolTipText={
              displayAcademies.length > 0
                ? '선택하신 위치 기반의 정보입니다.'
                : '위치 활성화를 켜서 내 지역을 확인하세요.'
            }
            clickable={true}
          >
            <div className="flex items-center gap-4">
              <div className="text-new-Body2-medium text-new-gray-600">
                {address?.fullAddress.split(' ')[0].slice(0, 2)} 기준
              </div>

              {/* <div className="bg-DTRed-400 flex h-[21px] w-[32px] flex-col items-center justify-center"> */}
              <NewIcon icon="info-outlined" className="fill-new-gray-400" size={20}></NewIcon>
              {/* </div> */}
            </div>
          </ToolTip>
        </div>
        <Spacer className="h-[16px]"></Spacer>
        {displayAcademies.length > 0 && (
          <div className="bg-new-white flex w-full  rounded-[12px] px-16 py-8">
            <AcademyList academies={displayAcademies}></AcademyList>
          </div>
        )}
      </div>
    </>
  );
};

export default HomeToAcademy;
