// Import the functions you need from the SDKs you need
import { getApps, initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, initializeFirestore, persistentLocalCache } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY ?? process.env.VITE_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN ?? process.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID ?? process.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket:
    process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET ?? process.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId:
    process.env.NEXT_PUBLIC_FIREBASE_MESSAGE_SENDER_ID ??
    process.env.VITE_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID ?? process.env.VITE_FIREBASE_APP_ID,
  measurementId:
    process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID ?? process.env.VITE_FIREBASE_MEASUREMENT_ID,
};

// Your web app's Firebase configuration
const backupFirebaseConfig = {
  apiKey:
    process.env.NEXT_PUBLIC_BACKUP_FIREBASE_API_KEY ?? process.env.VITE_BACKUPFIREBASE_API_KEY,
  authDomain:
    process.env.NEXT_PUBLIC_BACKUP_FIREBASE_AUTH_DOMAIN ??
    process.env.VITE_BACKUPFIREBASE_AUTH_DOMAIN,
  projectId:
    process.env.NEXT_PUBLIC_BACKUP_FIREBASE_PROJECT_ID ??
    process.env.VITE_BACKUPFIREBASE_PROJECT_ID,
  storageBucket:
    process.env.NEXT_PUBLIC_BACKUP_FIREBASE_STORAGE_BUCKET ??
    process.env.VITE_BACKUPFIREBASE_STORAGE_BUCKET,
  messagingSenderId:
    process.env.NEXT_PUBLIC_BACKUP_FIREBASE_MESSAGE_SENDER_ID ??
    process.env.VITE_BACKUPFIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_BACKUP_FIREBASE_APP_ID ?? process.env.VITE_BACKUPFIREBASE_APP_ID,
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const backupApp = initializeApp(backupFirebaseConfig, 'secondary');

if (getApps().length === 0) {
  initializeFirestore(app, {
    localCache: persistentLocalCache({}),
    ignoreUndefinedProperties: true,
  });
}

export const auth = getAuth(app);
export const db = getFirestore(app);
export const erpDB = getFirestore(app, 'erp-academy');
export const backupDB = getFirestore(backupApp);
export const storage = getStorage(app);
