export const WEB_QUERY_KEY = {
  ADDRESS: 'Address',
  WINDOW_SIZE: 'WindowSize',
  MY_LOCATION: 'MyLocation',
  RELATED_BLOGS: 'RelatedBlogs',
  KAKAO_CHANNEL: 'KakaoChannel',
  LIST_ACADEMIES_COUPONS: 'ListAcademiesCoupons',
  SCHOOL_LIST: 'SchoolList',
  ONSITE_COUPON: 'OnsiteCoupon',
  FIRST_COME_COUPON_CODES: 'FirstComeCouponCodes',
  CAN_DISCOUNT_ACADEMIES: 'CanDiscountAcademies',
  ACADEMIES: 'Academies',
  TODAY_PROBLEM: 'TodayProblem',
  LAST_PAGE_VIEW: 'LastPageView',
  HIGH_SCHOOL_SEARCH: 'HighSchoolSearch',
  UNIVERSITY_SEARCH: 'UniversitySearch',
  SCHOOL_RECENT_USERS: 'SchoolRecentUsers',
  SCHOOL_RECEIVED_WEB_TO_APP_VOTE: 'SchoolReceivedWebToAppVote',
  TRAINING_PV: 'TrainingPV',
  SIGUNGU_LOCATION: 'SigunguLocation',
  SIGUNGU_LONG_LOCATION: 'SigunguLongLocation',
};
