import { useEffect } from 'react';

import { getPlatform } from '@analytics/Analytics';
import { QueryClient } from '@tanstack/react-query';
import { QUERY_KEY, getAcademy, useGetAcademy } from 'database';
import { db } from 'firebase-config';
import { collection, getDocs, query } from 'firebase/firestore';
import { GetStaticProps } from 'next';
import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';

import { Loading, PageBase } from '@components';
import { Application } from '@templates';
import { ApplicationMetaTags } from '@templates/Application/ApplicationMetaTags';

import { customDehydrate } from '@config';
import { useFeatureFlagVariantKey } from 'posthog-js/react';

interface Params extends ParsedUrlQuery {
  id: string;
}

export async function getStaticPaths() {
  if (process.env.SKIP_BUILD_STATIC_GENERATION) {
    return {
      paths: [],
      fallback: 'blocking',
    };
  }

  const academyIDs: { id: string }[] = [];
  const q = query(collection(db, 'Academy'));
  const querySnapshot = await getDocs(q);

  querySnapshot.forEach((doc) => {
    const { isBookable = false, state = '' } = doc.data();
    // 예약 가능한 학원들만 paths에 추가
    if (state !== '입점' || !isBookable) return;
    academyIDs.push({ id: doc.id });
  });

  const paths = academyIDs.map((academy: { id: string }) => ({
    params: { id: academy.id },
  }));

  return {
    paths,
    fallback: 'blocking',
  };
}

/**
 * Application 페이지는 로그인을 요구하는 페이지이지만, 다른 웹사이트에서 링크하는 경우가 있어 (Ex : 학원 웹사이트)
 * SEO만을 위해 Meta 태그만을 정적 생성했습니다. 페이지의 체류 시간등이 매우 낮을 것으로 예상되어 SEO에 이것이 악영향을 미치는지는 지켜봐야 합니다.
 */
export const getStaticProps: GetStaticProps = async (context) => {
  if (process.env.NODE_ENV === 'development') return { props: {} };

  const queryClient = new QueryClient();

  const { id } = context.params as Params;

  await queryClient.prefetchQuery({
    queryKey: [QUERY_KEY.ACADEMY, id],
    queryFn: async () => await getAcademy({ queryKey: [QUERY_KEY.ACADEMY, id], queryClient }),
  });

  return {
    props: {
      dehydratedState: customDehydrate(queryClient),
    },
  };
};

export let isGlobalLinkFromAcademy = false;
export let isGlobalFromOnsiteEvent = false;
export const globalPreviousURL = { url: '' };

const AcademyApplicationPage = () => {
  const router = useRouter();
  const flag = useFeatureFlagVariantKey('application-login')

  const { isLinkFromAcademy, fromOnsiteEvent, id } = router.query;

  useEffect(() => {
    if (!isGlobalLinkFromAcademy && isLinkFromAcademy) {
      isGlobalLinkFromAcademy = true;
    }
  }, [isLinkFromAcademy]);

  useEffect(() => {
    if (!isGlobalFromOnsiteEvent && fromOnsiteEvent) {
      isGlobalFromOnsiteEvent = true;
    }
  }, [fromOnsiteEvent]);

  const { data, isLoading, isError } = useGetAcademy(id as string);

  if (isLoading || isError || !data) return <Loading />;

  return (
    <>
      <ApplicationMetaTags academy={data} />
      <PageBase
        userRequired={
          //!(isGlobalLinkFromAcademy || isLinkFromAcademy || fromOnsiteEvent)
          !getPlatform().includes('Web') && flag === 'control'
        }
      >
        <Application />
      </PageBase>
    </>
  );
};

export default AcademyApplicationPage;
