'use client';

import { ReactNode, createContext, useCallback, useState } from 'react';

import * as Portal from '@radix-ui/react-portal';
import * as RadixToast from '@radix-ui/react-toast';
import { AnimatePresence } from 'framer-motion';
import { cn } from 'tailwind-config';
import { v4 as uuidv4 } from 'uuid';

import { Toast, ToastProps } from './Toast';

export const ToastContext = createContext<{
  openToast: (arg: ToastProps | ((close: () => void) => ToastProps)) => () => void;
}>({
  openToast: () => {
    throw new Error(
      "You can't call openToast() outside of a <ToastProvider> – add it to your tree.",
    );
  },
});

const RootContainer = ({ children, isRoot }: { children: ReactNode; isRoot: boolean }) => {
  if (isRoot) {
    return <Portal.Root>{children}</Portal.Root>;
  }

  return <>{children}</>;
};

/**
 * Radix의 Toast를 사용했다가, Framer-motion과 통합이 힘들어서 (순정 radix는 animation을 컨트롤 하기 힘듬)
 * https://buildui.com/recipes/animated-toast 다음 조합을 참고해서 만듬.
 */
export function Toaster({
  children,
  viewportClassName,
  isRoot = false,
}: {
  children: ReactNode;
  viewportClassName?: string;
  isRoot?: boolean;
}) {
  const [messages, setMessages] = useState<({ id: string } & ToastProps)[]>([]);

  const close = useCallback((id: string) => {
    setMessages((toasts) => toasts.filter((t) => t.id !== id));
  }, []);

  const throttledOpenToast = useCallback(
    (arg: ToastProps | ((close: () => void) => ToastProps)) => {
      const { type = 'single' } = arg as ToastProps;
      const id = uuidv4();

      const toastProps = typeof arg === 'function' ? arg(() => close(id)) : arg;

      setMessages((prevToasts) => {
        if (type === 'multiple') {
          return [
            ...prevToasts,
            {
              id,
              ...toastProps,
            },
          ];
        }

        if (
          prevToasts.length < 1 ||
          // 2 title을 비교해서 다른 경우
          !prevToasts.some((t) => t.title === toastProps.title)
        ) {
          return [
            ...prevToasts,
            {
              id,
              ...toastProps,
            },
          ];
        }

        return prevToasts;
      });

      return () => close(id);
    },
    [],
  );
  return (
    <RadixToast.Provider>
      <ToastContext.Provider value={{ openToast: throttledOpenToast }}>
        {children}
      </ToastContext.Provider>
      <AnimatePresence>
        {messages.map((toast) => (
          <Toast
            {...toast}
            onClose={() => {
              close(toast.id);
              toast.onClose?.();
            }}
            key={toast.id}
          />
        ))}
      </AnimatePresence>
      <RootContainer isRoot={isRoot}>
        <RadixToast.Viewport
          className={cn(
            'pointer-events-none fixed bottom-0 left-[50%] z-[9999] flex w-full translate-x-[-50%] flex-col-reverse gap-3 px-16',
            viewportClassName,
          )}
        />
      </RootContainer>
    </RadixToast.Provider>
  );
}
