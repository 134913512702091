import { AcademyGeoJson, useGetAllAcademyGeoJson } from '@db/collections';
import { QUERY_KEY } from '@db/constants';
import { useQuery } from '@tanstack/react-query';
import { booleanPointInPolygon, point } from '@turf/turf';

import { ExploreMapAcademy } from './use-get-explore-academies/use-get-explore-academies';

export const checkInGeoJson = ({
  latitude,
  longitude,
  geoJson,
}: {
  latitude: number;
  longitude: number;
  geoJson: string[];
}) => {
  for (const indiv of geoJson) {
    if (booleanPointInPolygon(point([longitude, latitude]), JSON.parse(indiv))) {
      return true;
    }
  }
};

export const fetchShuttleBoundaryAcademies = async ({
  allGeoJson,
  academies,
  locations,
}: {
  allGeoJson: AcademyGeoJson[];
  academies: ExploreMapAcademy[] | Academy[];
  locations: LocationType[];
}) => {
  return academies.filter((academy) => {
    const findGeoJson = allGeoJson.find((geoJson) => geoJson.id === academy.id);
    if (!findGeoJson) return false;

    return locations.some(({ latitude, longitude }) =>
      checkInGeoJson({ latitude, longitude, geoJson: findGeoJson.geoJson }),
    );
  });
};

export const useGetShuttleBoundaryAcademies = <T extends ExploreMapAcademy[] | Academy[]>({
  locations,
  academies,
}: {
  locations: LocationType[];
  academies: T;
}) => {
  const { data: allGeoJson = [] } = useGetAllAcademyGeoJson();

  const result = useQuery({
    queryKey: [
      QUERY_KEY.ALL_ACADEMY_GEOJSON,
      allGeoJson.map((geo) => ({ id: geo.id })),
      academies.map((academy) => ({ id: academy.id })),
      locations.map(({ latitude, longitude }) => ({ latitude, longitude })),
    ],
    queryFn: () =>
      fetchShuttleBoundaryAcademies({
        allGeoJson,
        academies,
        locations,
      }),
    enabled: academies.length > 0 && locations.length > 0 && allGeoJson.length > 0,
  });

  return { ...result, isLoading: result.isLoading, data: result.data as T };
};
