import { useCallback, useEffect } from 'react';

import { LogEvent } from '@analytics';
import { useGetEventRoom, useGetUser } from 'database';
import { useToast } from 'design-system';
import { useRouter } from 'next/router';
import { findMethodAndReplace, formatDate } from 'shared-values';

import { useUserPreferenceMergeMutation } from '@templates/Event/Recommend/query';
import { useLoginStore } from '@templates/Login/Login';

import { api } from '@apis/hc';

import { ERROR_MESSAGE } from '@constants';
import { useEmit } from '@hooks';
import { checkDuplicateEmail } from '@utils/Auth';

import { getLoginState, loginExistUser, redirect, signUpNewUser } from './actions';
import { getUserInfoByProvider } from './actions/get-user-info';

interface KakaoRestData {
  provider: 'kakao-rest';
  code: string;
  state: string;
  host: string;
}

interface KakaoNativeData {
  provider: 'kakao-native';
  accessToken: string;
  idToken: string;
}

interface AppleNativeData {
  provider: 'apple-native';
  idToken: string;
  accessToken: string;
  userName: string;
  userEmail: string;
}

export interface UserInfo {
  idToken: string;
  firebaseIdToken: string;
  phoneNum: string;
  name: string;
  email: string;
  birthYear: string;
  accessToken: string;
  notificationAgreed: boolean;
  birthday: string;
  realName: string;
}

export type ProviderData = KakaoRestData | KakaoNativeData | AppleNativeData;
export type Provider = ProviderData['provider'];

/**
 * NOTE : 이벤트 리스너 방식으로 적용되기 때문에, 페이지 당 hook이 한 번만 선언되어야 함.
 */
export const useProcessLoginCallback = ({
  overrideRedirectUrl,
}: {
  overrideRedirectUrl?: string;
} = {}) => {
  const router = useRouter();
  const { setNeedMoreInfo, setIsLoading, setIsLoginProcessed, isLoading } = useLoginStore();
  const { openToast } = useToast();
  const { setUser } = useGetUser();
  const { getManually } = useGetEventRoom();
  const { triggerEmit } = useEmit('login');
  const { mutate: mergeUserPreference } = useUserPreferenceMergeMutation();

  // native 방식 카카오, 애플 로그인
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('sendIdToken', sendIdToken);
    }
    return () => {
      window.removeEventListener('sendIdToken', sendIdToken);
    };
  }, []);

  // rest api 방식 카카오 로그인
  useEffect(() => {
    if (!router.isReady) return;
    const { code = '', state = '' } = router.query as Record<string, string>;
    if (code.length === 0) return;
    login({
      provider: 'kakao-rest',
      code,
      state,
      host: window.location.host,
    });
  }, [router]);

  const sendIdToken = useCallback(async (e: any) => {
    const { idToken, provider, userName, accessToken = '', userEmail } = e.detail;

    if (provider === 'kakao') {
      login({
        provider: 'kakao-native',
        idToken,
        accessToken,
      });
    } else if (provider === 'apple') {
      login({
        provider: 'apple-native',
        idToken,
        userName,
        accessToken,
        userEmail,
      });
    }
  }, []);

  /**
   * @param
   * restData: rest API 방식에서 가져오는 값
   * nativeData : native API 방식에서 가져오는 값.
   */
  const login = async (providerData: ProviderData) => {
    setIsLoading(true);
    setIsLoginProcessed(true);

    try {
      // 1. login state 가져오기
      const loginState = await getLoginState({
        providerData,
        queryData: router.query as Record<string, string>,
      });

      // 2. userInfo 가져오기
      const userInfo = await getUserInfoByProvider(providerData);

      const { email, firebaseIdToken } = userInfo;

      // 3. 기존 회원 로그인인지, 신규 회원 회원가입인지 구분 후 처리
      const isUserExist = await checkDuplicateEmail(email);

      let userData = null;

      if (isUserExist) {
        userData = await loginExistUser(firebaseIdToken);
      } else {
        userData = await signUpNewUser({
          ...userInfo,
          provider: providerData.provider,
          firebaseIdToken,
          triggerEmit: async () => {
            setNeedMoreInfo(true);
            const emitResult = await triggerEmit();
            setNeedMoreInfo(false);
            return emitResult;
          },
        });
        LogEvent.유입.$회원가입(
          {
            userID: userData.uid,
            userEmail: userData.email,
            userPhone: userData.phoneNum,
            attributes: {
              userName: userData.name,
              userId: userData.uid,
              userEmail: userData.email,
              userPhone: userData.phoneNum,
              userRealName: userData.realName,
              userBirthday: userData.birthday,
            },
          },
          providerData.provider,
        );
      }

      if (!userData) throw new Error(ERROR_MESSAGE.AUTH_ERROR);

      findMethodAndReplace(userData, 'toDate');

      const user = setUser(userData);

      const coupon: IssuedCouponData | null = null;

      // 4. 로그인 이후 처리해줘야 하는 작업 (이벤트 등)

      const { roomId } = loginState;
      if (roomId && roomId.length > 0 && !user.hasEventRoom) {
        const eventRoom = await getManually(roomId);
        await eventRoom.addMember(user);
        await api.event.together['join-alim'].$post({
          json: {
            userId: eventRoom.leaderUser.id,
          },
        });
      }

      mergeUserPreference(user.uid);

      // 5. 리다리엑트 처리
      await redirect({
        router,
        loginState: {
          ...loginState,
          ...(overrideRedirectUrl ? { redirectUrl: overrideRedirectUrl } : {}),
          // 이미 이벤트에 참여한 유저라면, 초대 받은 방이 아닌 기존 방으로 이동
          roomId: roomId && roomId.length > 0 && user.hasEventRoom ? user.eventRoomId : roomId,
        },
        isUserExist,
        coupon,
      });

      // etc) 알림 처리
      // 가장 늦게 해줘야 하기 때문에 여기서 처리
      if (!isUserExist && userData) {
        if (userData.notificationAgreed) {
          openToast({
            title: `${formatDate(new Date(), 'YYYY년 MM월 DD일')} 마케팅 수신에 동의하셨습니다.`,
          });
        } else {
          openToast({
            title: `${formatDate(new Date(), 'YYYY년 MM월 DD일')} 마케팅 수신에 거부하셨습니다.`,
          });
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message);
        //
        if (error.cause === 'kakao-gift-balance') {
          await router.replace('/roulette/fail');
          return;
        } else if (error.cause === 'already-participated' || error.cause === 'invalid-code') {
          // 쿠폰을 확인시켜주기 위해 보관함으로 이동
          await router.replace('/profile/storage/coupon');
          return;
        }
      } else {
        alert(ERROR_MESSAGE.UNDEFINED);
      }
      await router.replace('/explore');
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading };
};
