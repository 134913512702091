import { ComponentProps, useRef, useState } from 'react';

import { Divider } from 'design-system';
import { useLocalStorage } from 'usehooks-ts';

import { useApplicationFunnel } from '@templates/Application/hooks';

import {
  Confirm,
  Payment,
  PurchaseAgreement,
  PurchaseBottom,
  ReservationInfo,
  ShuttleBusPreference,
  UserInfo,
} from './components';
import { PurchaseButton } from './components/PurchaseBottom/PurchaseButton';

export const Purchase = () => {
  const { academy, lessonInfo } = useApplicationFunnel<'Purchase'>();
  const [isKeyInPurchase, setIsKeyInPurchase] = useState<boolean>(true);
  const [checked, setChecked] = useLocalStorage('purchase-agreement', {
    info: false,
    private: false,
    refund: false,
  });

  const isAllChecked = checked.info && checked.private && checked.refund;
  // 순서대로 메시지의 우선순위가 결정 됨.
  const purchaseCondition = {
    agreement: { value: isAllChecked, message: '결제 동의가 필요해요.' },
  };

  const refs = useRef<Record<keyof typeof purchaseCondition, HTMLDivElement | null>>(
    Object.keys(purchaseCondition).reduce((acc, key) => {
      acc[key as keyof typeof purchaseCondition] = null;
      return acc;
    }, {} as Record<keyof typeof purchaseCondition, HTMLDivElement | null>),
  );

  return (
    <>
      <Divider className="-mx-16 h-6">
        <ReservationInfo />
      </Divider>
      <Divider className="-mx-16 h-6">
        <UserInfo />
      </Divider>
      <Divider className="-mx-16 h-6">
        <ShuttleBusPreference />
      </Divider>
      <Divider className="-mx-16 h-6">
        <Confirm />
      </Divider>
      <Divider className="-mx-16 h-6">
        <Payment isKeyInPurchase={isKeyInPurchase} setIsKeyInPurchase={setIsKeyInPurchase} />
      </Divider>
      <Divider
        ref={(el) => {
          el && (refs.current.agreement = el);
        }}
      >
        <PurchaseAgreement
          checked={checked}
          isAllChecked={isAllChecked}
          setChecked={setChecked}
          academy={academy}
          lessonInfo={lessonInfo}
        />
      </Divider>
      <PurchaseBottom
        purchaseCondition={Object.keys(purchaseCondition).reduce((acc, key) => {
          acc[key as keyof typeof purchaseCondition] = {
            ...purchaseCondition[key as keyof typeof purchaseCondition],
            ref: refs.current[key as keyof typeof purchaseCondition],
          };
          return acc;
        }, {} as ComponentProps<typeof PurchaseButton>['purchaseCondition'])}
        isKeyInPurchase={isKeyInPurchase}
      />
    </>
  );
};
