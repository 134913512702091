import { db } from 'firebase-config';
import { doc, getDoc } from 'firebase/firestore';

import { AcademyGeoJson } from '../models';

export const getAcademyGeoJsonData = async (id: string) => {
  const docRef = doc(db, 'AcademyGeoJson', id);
  const docData = (await getDoc(docRef)).data() as AcademyGeoJsonData;

  return new AcademyGeoJson({ ...docData });
};

// export const getAcademyShuttleAreaData = async (id: string) => {
//   const docRef = doc(db, 'AcademyGeoJsons', id);
//   const docData = (await getDoc(docRef)).data();

//   return { ...docData };
// };

export const getAcademyGeoJson = async ({ queryKey, queryClient }: QueryFn) => {
  const [, id] = queryKey as [string, string];
  return await getAcademyGeoJsonData(id);
};

// export const getAcademyShuttleArea = async ({ queryKey, queryClient }: QueryFn) => {
//   const [id] = queryKey as [string];
//   return await getAcademyShuttleAreaData(id);
// };
