import { useMemo } from 'react';

import { filterStateAtom } from '@jotaiStore';
import { distanceBetween } from 'geofire-common';
import { useAtomValue } from 'jotai';

import { useGetShuttleBoundaryAcademies } from '../../use-get-shuttle-boundary-academies';
import { useGetTargetLocation } from '../../use-get-target-location';
import { ExploreMapAcademy } from '../use-get-explore-academies';

const radiusInM = 50 * 1000; // 50000m = 50km

export const useGetFilteredAcademies = <T extends ExploreMapAcademy[] | Academy[]>(
  academies: T,
  location?: LocationType,
) => {
  const targetLocation = useGetTargetLocation();
  const filters = useAtomValue(filterStateAtom);

  const { data: shuttleBoundaryAcademies = [], isLoading: isShuttleBoundaryLoading } =
    useGetShuttleBoundaryAcademies<T>({
      locations: filters.shuttle.value,
      academies,
    });

  const shuttleFilteredAcademies = useMemo(() => {
    return filters.shuttle.value.length > 0 ? shuttleBoundaryAcademies : academies;
  }, [shuttleBoundaryAcademies, academies]);

  const distantFilteredAcademies = useMemo(() => {
    if (!targetLocation) return shuttleFilteredAcademies as unknown as T;

    return shuttleFilteredAcademies.filter((academy) => {
      const latitude = location ? location.latitude : targetLocation.latitude;
      const longitude = location ? location.longitude : targetLocation.longitude;

      const {
        location: { latitude: lat, longitude: lng },
      } = academy;

      const distanceInKm = distanceBetween([lat, lng], [latitude, longitude]);

      const distanceInM = distanceInKm * 1000;

      if (distanceInM <= radiusInM) {
        return true;
      }

      return false;
    }) as T;
  }, [targetLocation, shuttleFilteredAcademies, location]);

  return {
    isLoading: isShuttleBoundaryLoading,
    academies: distantFilteredAcademies,
  };
};
