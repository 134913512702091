import { ComponentProps } from 'react';

import { getDiscountPrice } from '@db/base-models';
import { useGetEventRoom, useGetFirstComeCouponCode, useGetUser } from '@db/collections';
import { NewBoxButton } from 'design-system';
import { useRouter } from 'next/router';

import { isGlobalLinkFromAcademy } from '@pages/academy/[id]/application';
import { useApplicationFunnel } from '@templates/Application/hooks';
import { 선착순쿠폰학원홈페이지허용학원 } from '@templates/Application/screens/Purchase/components/Confirm/components';

import { Funnel } from '@hooks/use-funnel';
import { usePageQuery, usePageRoute } from '@hooks/use-handle-url';

const LessonInfo = () => {
  const { lessonInfo, academy } = useApplicationFunnel();

  const { data: firstComeCoupon } = useGetFirstComeCouponCode(academy?.id, false);
  const { applicationMode } = usePageQuery<'/academy/[id]/application'>();
  const router = useRouter();
  const { forceUseFirstCome } = router.query;

  if (!lessonInfo) return null;

  const { lessonName, lessonDiscountPrice, lessonTotalPrice, lessonPrice, lessonCode } = lessonInfo;

  if (applicationMode === 'together-select') {
    return (
      <>
        <div className="w-full pt-5">
          <div className="text-Body4 leading-[22.4px] text-gray-600">{lessonName}</div>
          <div>
            <div className="text-Body1 leading-[25.6px]">{lessonPrice.toLocaleString()}원</div>
          </div>
        </div>
      </>
    );
  }

  const discountResult = getDiscountPrice({
    price: lessonTotalPrice,
    discounts:
      firstComeCoupon &&
      (Boolean(forceUseFirstCome) ||
        (firstComeCoupon.canUse &&
          (선착순쿠폰학원홈페이지허용학원.includes(academy ? academy.id : '') ||
            !isGlobalLinkFromAcademy))) &&
      firstComeCoupon.discount.canApply({
        lessonCode,
      })
        ? [firstComeCoupon.discount]
        : [],
  });

  const totalDiscountPrice = discountResult.할인금 + lessonDiscountPrice;

  return (
    <>
      <div className="w-full pt-5">
        <div className="text-Body4 leading-[22.4px] text-gray-600">{lessonName}</div>
        <div>
          {totalDiscountPrice > 0 ? (
            <div className="flex items-center gap-4">
              <div className="text-Body1 text-DTRed-400 leading-[25.6px]">
                {(discountResult.원금 - totalDiscountPrice).toLocaleString()}원
              </div>
              <span className="text-[13px] text-gray-400 line-through	">
                {discountResult.원금.toLocaleString()}원
              </span>
            </div>
          ) : (
            <div className="text-Body1 leading-[25.6px]">
              {discountResult.최종금.toLocaleString()}원
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export const ApplicationBottom = ({
  buttonOption,
  children,
}: {
  buttonOption?: Partial<ComponentProps<typeof NewBoxButton>>;
  children?: (props: { onClick: () => void; disabled: boolean }) => JSX.Element;
}) => {
  const { goNext, lessonInfo, selectedTime, selectedRange, currentScreen } = useApplicationFunnel();
  const { applicationMode } = usePageQuery('/academy/[id]/application');
  const handleRoute = usePageRoute('/event/together/room/[id]');
  const { user } = useGetUser();
  const { getManually } = useGetEventRoom();

  if (!lessonInfo || currentScreen === 'Purchase') return null;

  const handleButtonDisabled = (currentScreen: string) => {
    switch (currentScreen) {
      case 'RegistTypeSelect':
      case 'LessonSelect':
        return false;
      case 'DateSelect':
        return !(selectedRange || selectedTime); // selectedRange 또는 selectedTime이 없을 때만 true 반환
      default:
        return false;
    }
  };

  const onClickGoNext = async () => {
    if (currentScreen === 'DateSelect' && applicationMode === 'together-select' && user) {
      const eventRoom = await getManually(user.eventRoomId);
      if (!eventRoom) throw new Error('eventRoom is not exist');
      await eventRoom.selectLesson({
        user,
        lessonInfo,
        selectedTime,
        selectedRange,
      });
      await handleRoute('together-select-complete', {
        url: `/event/together/room/${eventRoom.id}`,
      });
      return;
    }

    goNext();
  };

  return (
    <Funnel.Bottom>
      <div className="flex w-full justify-between bg-white">
        <LessonInfo />
        <div className="!w-[171px] flex-shrink-0">
          {children ? (
            children({
              onClick: onClickGoNext,
              disabled: handleButtonDisabled(currentScreen),
            })
          ) : (
            <NewBoxButton
              label="다음"
              fill
              styles="filled-yellow"
              disabled={handleButtonDisabled(currentScreen)}
              onClick={onClickGoNext}
              {...buttonOption}
            />
          )}
        </div>
      </div>
    </Funnel.Bottom>
  );
};
