import { useExploreStore } from 'src/store/explore';
import { useShallow } from 'zustand/react/shallow';

import { useGetShowBottomNavbar } from '@components/Layout/Floating';

import {
  BOTTOM_NAV_BAR_HEIGHT,
  EXPLORE_HEADER_HEIGHT,
  TOGETHER_EVENT_BOTTOM_ACADEMY_DESCRIPTION_HEIGHT,
  TOGETHER_EVENT_BOTTOM_SELECT_BAR_HEIGHT,
} from '@constants';
import { useWindowSize } from '@hooks';
import { isAppApproaching } from '@utils/Common';

const isAppApproach = isAppApproaching() && !window.isBottomNavbarVisible;
export const useGetInnerHeight = ({
  isFromTeamEvent,
  selectedAcademy,
}: {
  isFromTeamEvent: boolean;
  selectedAcademy: DisplayAcademy | null;
}) => {
  const { showBottomNavbar } = useGetShowBottomNavbar();

  const mode = useExploreStore(useShallow((s) => s.mode.value));

  const { height } = useWindowSize({
    isTopNavbarHeight: false,
  });

  const innerHeight =
    height -
    (mode === 'list' ? EXPLORE_HEADER_HEIGHT : 0) -
    ((!isAppApproach || window.isBottomNavbarVisible) && showBottomNavbar
      ? BOTTOM_NAV_BAR_HEIGHT
      : 0) -
    (isFromTeamEvent
      ? TOGETHER_EVENT_BOTTOM_SELECT_BAR_HEIGHT + TOGETHER_EVENT_BOTTOM_ACADEMY_DESCRIPTION_HEIGHT
      : 0);

  return innerHeight;
};
