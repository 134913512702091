import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY } from 'database';

import { getMapAddress } from '@apis';

import { useGetTargetLocation } from './use-get-target-location';

export const useGetDisplayAddress = (selectedAcademy: DisplayAcademy | null) => {
  const targetLocation = useGetTargetLocation();

  const { data: mapAddress } = useQuery({
    queryKey: [QUERY_KEY.MAP_ADDRESS, targetLocation as LocationType],
    queryFn: getMapAddress,
    enabled: Boolean(targetLocation),
  });

  const displayAddress = selectedAcademy
    ? selectedAcademy.name
    : mapAddress
    ? mapAddress.mapAddress
    : '';

  return { displayAddress, isAddressLoading: displayAddress.length === 0 };
};
