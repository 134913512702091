// Import the AcademySortOrder type
import { QUERY_KEY } from '@db/constants';
import { useInfiniteQueryWithClient } from '@db/hooks';
import { filterStateAtom } from '@jotaiStore';
import { DisplayAcademy } from '@models';
import { distanceBetween } from 'geofire-common';
import { useAtomValue } from 'jotai';
import { pipe } from 'shared-values';

import { useGetTargetLocation } from '@templates/Explore/Explore/hooks';
import { useGetExploreAcademies } from '@templates/Explore/Explore/hooks/use-get-explore-academies/use-get-explore-academies';

const ITEMS_PER_PAGE = 10;

export const sortByDistance = (
  academies: DisplayAcademy[],
  targetLocation: LocationType | null,
) => {
  if (!targetLocation) return academies;

  return academies.sort((a, b) => {
    const distanceA = distanceBetween(
      [a.location.latitude, a.location.longitude],
      [targetLocation.latitude, targetLocation.longitude],
    );

    const distanceB = distanceBetween(
      [b.location.latitude, b.location.longitude],
      [targetLocation.latitude, targetLocation.longitude],
    );
    // `오름차순
    return distanceA - distanceB;
  });
};

export const sortByPrice = (academies: DisplayAcademy[]) => {
  const priceInquiryList = academies.filter((academy) => academy.lesson.isPriceInquiry);
  const notPriceInquiryList = academies.filter((academy) => !academy.lesson.isPriceInquiry);

  return [
    // 오름차순
    ...notPriceInquiryList.sort((a, b) => a.lesson.totalPrice - b.lesson.totalPrice),
    ...priceInquiryList,
  ];
};

export const sortByReview = (academies: DisplayAcademy[]) => {
  return academies.sort((a, b) => {
    if (a.totalReviewCount === b.totalReviewCount) {
      return b.averageRate.totalAverage - a.averageRate.totalAverage;
    }
    return b.totalReviewCount - a.totalReviewCount;
  });
};

export const useGetInfiniteAcademies = () => {
  const { academies, isLoading } = useGetExploreAcademies('list');
  const targetLocation = useGetTargetLocation();

  const filter = useAtomValue(filterStateAtom);

  const result = useInfiniteQueryWithClient({
    queryKey: [QUERY_KEY.INFINITE_ACADEMIES, academies, filter],
    queryFn: ({ queryKey, pageParam = 0 }) => {
      const [, academies, filter] = queryKey as [string, Academy[], AcademyFilters];
      const startIdx = pageParam * ITEMS_PER_PAGE;
      const endIdx = startIdx + ITEMS_PER_PAGE;
      const {
        sortOrder: { value: order },
        lesson: { value: lessonCode },
      } = filter;

      const sortedAcademies = pipe<DisplayAcademy>(
        order === '가까운 순'
          ? (academies) => sortByDistance(academies, targetLocation)
          : order === '가격 낮은 순'
          ? (academies) => sortByPrice(academies)
          : (academies) => academies,
      )(academies.map((a) => new DisplayAcademy({ academy: a, lessonCode })));

      const academiesForPage = sortedAcademies.slice(startIdx, endIdx);

      return academiesForPage;
    },
    getNextPageParam: (lastPage, allPages) => {
      // If the last page has no data, stop loading more pages.
      if (!lastPage.length) return undefined;
      return allPages.length;
    },
    enabled: academies.length > 0,
    gcTime: 0,
    staleTime: 0,
    initialPageParam: 0,
  });

  return { ...result, isLoading: isLoading || result.isLoading };
};
