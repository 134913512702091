import { PropsWithChildren } from 'react';

import { cn } from 'tailwind-config';

interface Props {
  size?: 'medium' | 'large';
}

export default function AdvertisementLabel({
  children,
  size = 'medium',
}: PropsWithChildren<Props>) {
  return (
    <div
      className={cn(
        'text-new-white  rounded-[4px] bg-gradient-to-tr from-[#3D73EB] to-[#DE8FFF]  py-2 text-center',
        size === 'medium' ? 'text-new-Caption2-bold px-6' : 'text-new-Body2-bold px-8',
      )}
    >
      {children}
    </div>
  );
}
