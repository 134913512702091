import { NewIcon } from 'design-system';
import Image from 'next/image';
import { useRouter } from 'next/router';
import posthog from 'posthog-js';

interface Props {
  academies: DisplayAcademy[];
}

const AcademyList = (props: Props) => {
  const { academies } = props;

  const router = useRouter();

  return (
    <div className="flex w-full flex-col gap-8">
      {academies.map((academy, index) => (
        <button
          key={academy.id}
          className="flex w-full items-center gap-16 py-8"
          onClick={() => {
            posthog.capture('click_academy_home_to_detail', { academy_id: academy.id });
            router.push(`/academy/${academy.id}`);
          }}
        >
          <div className="bg-new-gray-200 text-new-gray-900 text-new-Body2-bold line-center flex h-[24px] w-[24px] items-center justify-center rounded-full text-center">
            {index + 1}
          </div>
          <Image
            loading="lazy"
            src={academy.image ?? '/no-image.png'}
            width={112}
            height={112}
            className=" border-1 border-new-gray-200  h-[56px] w-[56px] rounded-full object-cover"
            alt="운전선생 학원 이미지"
          ></Image>
          <div className="flex flex-col items-start gap-2">
            <div className="text-new-Body2-bold text-new-gray-900">{academy.displayName}</div>
            <div className="flex items-center gap-4">
              <div className="text-new-Body1-bold text-new-gray-900">
                {academy.lesson.renderPrice}
              </div>
              <div className="flex items-center">
                <div className="flex items-center gap-2">
                  <div className="flex h-full items-center justify-center">
                    <NewIcon icon="star-filled" size={12} className="fill-new-DTPurple-500" />
                  </div>
                  <p className="text-new-Caption1-bold block">{academy.averageRate.totalAverage}</p>
                </div>
                <p className="text-new-Caption1-medium text-new-gray-500 block pl-4">
                  후기 {academy.totalReviewCount}
                </p>
              </div>
            </div>
          </div>
        </button>
      ))}
    </div>
  );
};

export default AcademyList;
