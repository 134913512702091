import { QUERY_KEY } from '@db/constants';

import { useQueryWithClient } from '../../../hooks';
import { getAcademy } from '../calls';

export const useGetAcademy = (id?: string) => {
  const queryKey = [QUERY_KEY.ACADEMY, id ?? ''];

  const result = useQueryWithClient({
    queryKey,
    queryFn: getAcademy,
    enabled: !!id && id !== '',
  });

  return result;
};
