'use client';

import { BottomSheetWrapper } from '@ds/components/deprecated/BottomSheet/components';
import Image from 'next/image';

interface PromotionModalProps {
  isOpen: boolean;
  close: () => void;
  onTodayNoShowClick: () => void;
  promotion: {
    imageUrl: string;
    onClick: () => void;
  };
}

const PromotionModal = ({ isOpen, close, onTodayNoShowClick, promotion }: PromotionModalProps) => {
  return (
    <BottomSheetWrapper isOpen={isOpen} onClose={close}>
      <div
        className="flex cursor-pointer flex-col items-center justify-center overflow-hidden rounded-t-3xl"
        onClick={() => {
          promotion.onClick();
          close();
        }}
      >
        <Image
          src={promotion.imageUrl}
          width={500}
          height={204}
          style={{ height: 'auto' }}
          alt="프로모션 모달"
          priority
        />
      </div>
      <div className="bg-new-white border-x-1 flex h-60 justify-between border-[#e0e0d1] p-16 pb-24">
        <span
          className="text-new-Body2-medium cursor-pointer"
          onClick={() => {
            onTodayNoShowClick();
            close();
          }}
        >
          오늘 하루 안 보기
          {/* 다시 보지 않기 */}
        </span>
        <span
          className="text-new-Body2-bold cursor-pointer"
          onClick={() => {
            close();
          }}
        >
          닫기
        </span>
      </div>
    </BottomSheetWrapper>
  );
};

export default PromotionModal;
