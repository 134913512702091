import { useEffect } from 'react';

import { atom, useAtom } from 'jotai';
import { atomWithReset } from 'jotai/utils';
import { useFeatureFlagVariantKey } from 'posthog-js/react';

import { atomWithSuperJsonLocalStorage } from './storage';

export const FilterDefaultValue: AcademyFilters = {
  lesson: { value: 'TWO_AUTO_NEW', label: '2종 자동' },
  reservationRange: { value: null, label: '' },
  sortOrder: { value: '가까운 순', label: '가까운 순' },
  shuttle: { value: [], label: '' },
  isOnlyBookable: { value: true, label: '' },
  additionalProperties: { value: [], label: '' },
};

export const filterStateAtom = atomWithReset<AcademyFilters>(FilterDefaultValue);

export const useFilterState = () => {
  const [filterState, setFilterState] = useAtom(filterStateAtom);
  const isTest = useFeatureFlagVariantKey('explore-listview-sortby-reviews') === 'test';

  useEffect(() => {
    setFilterState({
      ...FilterDefaultValue,
      sortOrder: {
        value: isTest ? '후기 많은 순' : '가까운 순',
        label: isTest ? '후기 많은 순' : '가까운 순',
      },
    });
  }, [isTest, setFilterState]);

  return [filterState, setFilterState] as const;
};

export const searchedAcademyStateAtom = atom<boolean>(false);

export const recentSearchStateAtom = atomWithSuperJsonLocalStorage<
  SearchAcademyData[] | SearchAddressData[]
>('recentSearchState', []);

export const simulationLaunchCoachMarkStateAtom = atomWithSuperJsonLocalStorage<{
  onExplore: boolean;
  onAcademy: string[];
  onEvent: boolean;
}>('simulationLaunchCoachMark', {
  onExplore: true,
  onAcademy: [],
  onEvent: true,
});
