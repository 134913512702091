import { db } from 'firebase-config';
import { addDoc, collection } from 'firebase/firestore';
import { flatten } from 'flat';

import { BaseLogEvent, BaseParams, sessionId } from '../BaseLogEvent';

export class 매출 extends BaseLogEvent {
  constructor(params: BaseParams) {
    super(params);
  }

  public $배송지추가(academy: Academy, applicationMode: string) {
    const addShippingInfoParams = {
      ...this.params.ecommerce.getGAAcademyParams(academy, this.location, applicationMode),
      ...this.params.common.getAcademyParams(academy),
      ...this.params.common.getDistanceParams(this.location, academy.location),
    };
    this.GAEvent('add_shipping_info', addShippingInfoParams);
    this.FlarelaneEvent.trackEvent('purchase_view', {
      ...flatten(addShippingInfoParams),
      screen: 'add_shipping_info',
    });

    this.FlarelaneEvent.setTags({
      last_funnel_academy_id: academy.id,
      last_funnel_academy_name: academy.name,
    });

    this.AirbridgeEvent.sendIndividual('add_shipping_info', {
      ...this.params.ecommerce.getAirbridgeAcademyParams(academy, applicationMode),
    });
    this.posthog.capture('add_shipping_info', addShippingInfoParams);
  }
  public $위시리스트추가(academy: Academy, applicationMode: string) {
    const addToWishlistParams = {
      ...this.params.ecommerce.getGAAcademyParams(academy, this.location, applicationMode),
      ...this.params.common.getAcademyParams(academy),
      ...this.params.common.getDistanceParams(this.location, academy.location),
    };
    this.GAEvent('add_to_wishlist', addToWishlistParams);
    this.FlarelaneEvent.trackEvent('purchase_view', {
      ...flatten(addToWishlistParams),
      screen: 'add_to_wishlist',
    });
    this.FlarelaneEvent.setTags({
      last_funnel_academy_id: academy.id,
      last_funnel_academy_name: academy.name,
    });

    this.AirbridgeEvent.sendIndividual('airbridge.addToWishlist', {
      ...this.params.ecommerce.getAirbridgeAcademyParams(academy, applicationMode),
    });
    this.posthog.capture('add_to_wishlist', addToWishlistParams);
  }
  public $장바구니에추가(academy: Academy, lesson: Lesson, applicationMode: string) {
    const addToCartParams = {
      ...this.params.ecommerce.getGALessonParams(academy, lesson, applicationMode, this.location),
      ...this.params.common.getAcademyParams(academy),
      ...this.params.common.getLessonParams(lesson),
      ...this.params.common.getDistanceParams(this.location, academy.location),
    };
    this.GAEvent('add_to_cart', addToCartParams);
    this.FlarelaneEvent.trackEvent('purchase_view', {
      ...flatten(addToCartParams),
      screen: 'add_to_cart',
    });
    this.FlarelaneEvent.setTags({
      last_funnel_academy_id: academy.id,
      last_funnel_academy_name: academy.name,
    });

    this.AirbridgeEvent.addedToCart({
      ...this.params.ecommerce.getAirbridgeLessonParams(academy, lesson, applicationMode),
    });

    this.NaverEvent.trackEvent({
      type: 'add_to_cart',
      id: '',
      items: [this.params.ecommerce.getNaverLessonParams(academy, lesson)],
      value: lesson.price.toString(),
    });
    this.posthog.capture('add_to_cart', addToCartParams);
  }
  public $결제수단등록(
    academy: Academy,
    lesson: Lesson,
    applicationMode: string,
    userProperties: {
      latitude: number;
      longitude: number;
      locationMethod: string;
    },
  ) {
    const addPaymentInfoParams = {
      ...this.params.ecommerce.getGALessonParams(academy, lesson, applicationMode, this.location),
      ...this.params.common.getAcademyParams(academy),
      ...this.params.common.getLessonParams(lesson),
      ...this.params.common.getDistanceParams(this.location, academy.location),
    };
    this.GAEvent('add_payment_info', addPaymentInfoParams);
    this.FlarelaneEvent.trackEvent('purchase_view', {
      ...flatten(addPaymentInfoParams),
      screen: 'add_payment_info',
    });
    this.FlarelaneEvent.setTags({
      last_funnel_academy_id: academy.id,
      last_funnel_academy_name: academy.name,
    });

    this.AirbridgeEvent.sendIndividual('add_payment_info', {
      ...this.params.ecommerce.getAirbridgeLessonParams(academy, lesson, applicationMode),
    });

    this.NaverEvent.trackEvent({
      type: 'begin_checkout',
      id: '',
      items: [this.params.ecommerce.getNaverLessonParams(academy, lesson)],
      value: lesson.price.toString(),
    });
    this.posthog.capture('add_payment_info', {
      ...addPaymentInfoParams,
      user_location_properties: userProperties,
    });
  }
  public $결제시작(
    academy: Academy,
    lesson: Lesson,
    applicationMode: string,
    purchase_mothod: string,
    discounts: Discount[],
  ) {
    const beginCheckoutParams = {
      ...this.params.ecommerce.getGALessonParams(academy, lesson, applicationMode, this.location),
      ...this.params.ecommerce.getGADiscountParams(lesson.price, discounts),
      ...this.params.common.getAcademyParams(academy),
      ...this.params.common.getLessonParams(lesson),
      ...this.params.common.getDistanceParams(this.location, academy.location),
      purchase_mothod: purchase_mothod,
    };

    this.GAEvent('begin_checkout', beginCheckoutParams);

    this.GAEvent('purchase_mothod', {
      purchase_mothod: purchase_mothod,
      ...this.params.common.getAcademyParams(academy),
      ...this.params.common.getLessonParams(lesson),
      ...this.params.common.getDistanceParams(this.location, academy.location),
    });

    this.purchaseButtonClick();

    if (discounts.length > 0) {
      this.AirbridgeEvent.sendIndividual('airbridge.spendCredits', {
        ...this.params.ecommerce.getAirbridgeLessonParams(academy, lesson, applicationMode),
        ...this.params.ecommerce.getGADiscountParams(lesson.price, discounts),
      });
    }

    this.AirbridgeEvent.sendIndividual('airbridge.initiateCheckout', {
      ...this.params.ecommerce.getAirbridgeLessonParams(academy, lesson, applicationMode),
      ...this.params.ecommerce.getGADiscountParams(lesson.price, discounts),
    });

    this.NaverEvent.trackEvent({
      type: 'begin_checkout',
      id: '',
      items: [this.params.ecommerce.getNaverLessonParams(academy, lesson)],
      value: lesson.price.toString(),
    });
  }

  public $구매완료(
    academy: Academy,
    lesson: Lesson,
    applicationMode: string,
    transactionID: string,
    discounts: Discount[],
    userId: string,
  ) {
    const purchaseParams = {
      ...this.params.ecommerce.getGALessonParams(academy, lesson, applicationMode, this.location),
      ...this.params.ecommerce.getGADiscountParams(lesson.price, discounts),
      ...this.params.common.getAcademyParams(academy),
      ...this.params.common.getLessonParams(lesson),
      ...this.params.common.getDistanceParams(this.location, academy.location),
      affiliation: '운전선생',
      transaction_id: transactionID,
      user_id: userId,
    };

    this.GAEvent('purchase', purchaseParams);

    const airbridgeOption = {
      ...this.params.ecommerce.getAirbridgeLessonParams(academy, lesson, applicationMode),
      ...this.params.ecommerce.getGADiscountParams(lesson.price, discounts),
    };

    this.AirbridgeEvent.purchased({
      ...airbridgeOption,
      semanticAttributes: {
        ...airbridgeOption.semanticAttributes,
        inAppPurchased: false,
        transactionID,
      },
    });

    this.purchaseButtonReturn('success');

    this.NaverEvent.trackEvent({
      type: 'purchase',
      id: '',
      items: [this.params.ecommerce.getNaverLessonParams(academy, lesson)],
      value: lesson.price.toString(),
    });
    this.posthog.capture('purchase', purchaseParams);
  }

  public $환불(
    academy: Academy,
    lesson: Lesson,
    applicationMode: string,
    transactionID: string,
    discounts: Discount[],
  ) {
    const refundParams = {
      ...this.params.ecommerce.getGALessonParams(academy, lesson, applicationMode, this.location),
      ...this.params.ecommerce.getGADiscountParams(lesson.price, discounts),
      ...this.params.common.getAcademyParams(academy),
      ...this.params.common.getLessonParams(lesson),
      ...this.params.common.getDistanceParams(this.location, academy.location),
      affiliation: '운전선생',
      transaction_id: transactionID,
    };

    this.GAEvent('refund', refundParams);

    const airbridgeOption = {
      ...this.params.ecommerce.getAirbridgeLessonParams(academy, lesson, applicationMode),
      ...this.params.ecommerce.getGADiscountParams(lesson.price, discounts),
    };

    this.AirbridgeEvent.sendIndividual('airbridge.ecommerce.order.canceled', {
      ...airbridgeOption,
      semanticAttributes: {
        ...airbridgeOption.semanticAttributes,
        transactionID,
      },
    });
  }

  public completePageView(academy: Academy, lesson: Lesson) {
    this.GAEvent('pv_complete', {
      ...this.params.common.getAcademyParams(academy),
      ...this.params.common.getLessonParams(lesson),
      ...this.params.common.getAcademyParams(academy),
      ...this.params.common.getLessonParams(lesson),
      ...this.params.common.getDistanceParams(this.location, academy.location),
    });
  }

  public togetherRoomCreatePageView() {
    this.GAEvent('pv_together_room_create', {});
  }

  public togetherRoomPageView(academy: {
    id: string;
    locationAttachedName: string;
    type: string;
    state: Academy['state'];
  }) {
    this.GAEvent('pv_together_room', {
      ...this.params.common.getAcademyParams(academy),
    });
  }

  public verificationClick() {
    sessionStorage.setItem('purchase_verification', 'true');
    this.GAEvent('purchase_verification', {
      purchase_verification: 'click',
    });
  }

  public verificationReturn(action: 'success' | 'fail') {
    const purchase_verification = sessionStorage.getItem('purchase_verification');

    if (!purchase_verification) return;
    sessionStorage.removeItem('purchase_verification');
    this.GAEvent('purchase_verification', {
      purchase_verification: action,
    });
  }

  public purchaseButtonClick() {
    this.GAEvent('purchase_button', {
      purchase_button: 'click',
    });
  }

  public purchaseButtonReturn(action: 'success' | 'fail') {
    const purchase_button = sessionStorage.getItem('purchase_button');

    if (!purchase_button) return;
    sessionStorage.removeItem('purchase_button');
    this.GAEvent('purchase_button', {
      purchase_button: action,
    });
  }

  public clickReservationReturn() {
    this.GAEvent('click_reservation_return', {});
  }
  public showReservationReturn() {
    this.GAEvent('show_reservation_return', {});
  }
  public closeReservationReturn() {
    this.GAEvent('close_reservation_return', {});
  }

  public academyCourseEnter({
    academy,
    position,
    subject,
  }: {
    academy: Academy;
    position: string;
    subject: '기능' | '도로';
  }) {
    this.GAEvent('academy_course_enter', {
      position,
      subject,
      start_time: new Date().getTime(),
      ...this.params.common.getAcademyParams(academy),
    });
  }
  public academyCourseExit({
    academy,
    position,
    subject,
  }: {
    academy: Academy;
    position: string;
    subject: '기능' | '도로';
  }) {
    this.GAEvent('academy_course_exit', {
      position,
      subject,
      end_time: new Date().getTime(),
      ...this.params.common.getAcademyParams(academy),
    });
  }

  public trainingApplyClick(label: string) {
    this.GAEvent('training_apply_click', {
      label,
    });
  }
  public academyPromotion(action: 'show' | 'click') {
    this.GAEvent('academy_promotion', {
      action,
    });
  }
  public driverGuide(action: 'login_click') {
    this.GAEvent('driver_guide', {
      action,
    });
  }
  public fastLicenseAction(action: string, label?: string) {
    this.GAEvent('fast_license_action', {
      action,
      ...(label !== undefined ? { label } : {}),
    });

    this.AirbridgeEvent.sendIndividual('fast_license_action', {
      action,
      ...(label !== undefined ? { label } : {}),
    });

    this.posthog.capture('fast_license_action', {
      action,
      ...(label !== undefined ? { label } : {}),
    });

    window.fbq('trackCustom', 'fast_license_action', { action });
  }

  public visitTrainingFunnel(screen: string) {
    this.GAEvent('visit_training_funnel', {
      screen,
    });

    this.AirbridgeEvent.sendIndividual(`visit_training.${screen}`, {});
  }
  public visitTrainingLocationSelect({
    reservationLocation,
    dong,
    academyId,
  }: {
    reservationLocation: NonNullable<VisitTrainingReceiptData['reservationLocation']>;
    dong: string;
    academyId: string;
  }) {
    this.FlarelaneEvent.trackEvent('visit_training.location_select');
    this.FlarelaneEvent.setTags({
      visit_training_location: `${reservationLocation.sigungu} ${dong}`,
    });
    this.AirbridgeEvent.sendIndividual(`visit_training.location_select`);
    addDoc(collection(db, 'VisitTrainingUserLocation'), {
      reservationLocation: {
        sido: reservationLocation?.sido ?? '',
        sigungu: reservationLocation?.sigungu ?? '',
        dong: dong ?? '',
      },
      academyId: academyId,
      userId: this.user?.uid,
      session_id: sessionId(),
    });
  }

  public exploreRecommendation(action: string, label?: string) {
    this.GAEvent('explore_recommendation', {
      action,
      label,
    });

    this.AirbridgeEvent.sendIndividual('explore.recommendation', {
      action,
      label,
    });
  }

  public couponIssued({
    couponPrice,
    couponName,
  }: {
    couponPrice: number | string;
    couponName: string;
  }) {
    this.GAEvent('coupon_issued', {
      coupon_price: couponPrice,
      name: couponName,
    });
  }
}
