import { useEffect, useState } from 'react';

import { targetLocationSelectorFamilyAtom } from '@jotaiStore';
import { useAtomValue } from 'jotai';
import { useRouter } from 'next/router';

import { useGetLocation } from '@hooks';
import { getSavedFunnelLocation, getSavedLatestLocation } from '@utils/location';

export const useGetTargetLocation = () => {
  const {
    isReady,
    query: { lat = '', lng = '', init },
  } = useRouter();
  const { myLocation } = useGetLocation();
  const [storageLocation, setStorageLocation] = useState<{
    latitude: number;
    longitude: number;
  } | null>(null);

  const targetLocation = useAtomValue(
    targetLocationSelectorFamilyAtom({
      routerState: { isReady, lat: lat as string, lng: lng as string },
      myLocation,
      savedLatestLocation: storageLocation,
    }),
  );

  useEffect(() => {
    const localStorageLocation = getSavedLatestLocation();
    const funnelLocation = getSavedFunnelLocation();
    if (init) {
      if (funnelLocation) {
        setStorageLocation({
          latitude: funnelLocation.latitude,
          longitude: funnelLocation.longitude,
        });
        return;
      }
      if (localStorageLocation) {
        setStorageLocation({
          latitude: localStorageLocation.latitude,
          longitude: localStorageLocation.longitude,
        });
        return;
      }
    }
  }, [init]);

  return targetLocation;
};
