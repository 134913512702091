import { useEffect, useMemo, useState } from 'react';

import { getMapAddressData } from '@apis/auth/get-map-address';

import { useGetLocation } from '@hooks/use-get-location/use-get-location';
import { getSavedFunnelLocation, getSavedLatestLocation } from '@utils/location';

const useGetAdvertisementLocation = (offMapLocation?: boolean) => {
  const { locationPermission, myLocation } = useGetLocation({ isSilent: true });
  const userMapLocation = getSavedLatestLocation();
  const funnelLocation = getSavedFunnelLocation();

  const [jibunAddress, setJibunAddress] = useState<string | null>(null);

  const [locationMethod, setLocationMethod] = useState<
    'myLocation' | 'userMapLocation' | 'funnelLocation' | null
  >(null);

  const locationCoordinates = useMemo(() => {
    if (funnelLocation) {
      setLocationMethod('funnelLocation');
      return {
        latitude: funnelLocation.latitude,
        longitude: funnelLocation.longitude,
      };
    }

    if (locationPermission !== 'denied' && myLocation) {
      setLocationMethod('myLocation');
      return {
        latitude: myLocation.latitude,
        longitude: myLocation.longitude,
      };
    }

    if (!offMapLocation && userMapLocation) {
      setLocationMethod('userMapLocation');
      return {
        latitude: userMapLocation.latitude,
        longitude: userMapLocation.longitude,
      };
    }

    return null;
  }, []);

  useEffect(() => {
    const getAddressByCoordinates = async () => {
      if (locationCoordinates) {
        return await getMapAddressData(locationCoordinates);
      }
    };

    const fetchAddress = async () => {
      const address = await getAddressByCoordinates();

      setJibunAddress(address?.mapAddress || null);
    };

    fetchAddress();
  }, [locationCoordinates]);

  if (locationCoordinates?.latitude && locationCoordinates?.longitude) {
    return {
      lat: locationCoordinates.latitude,
      lng: locationCoordinates.longitude,
      jibunAddress,
      locationPermission,
      locationMethod,
    };
  }

  return null;
};

export default useGetAdvertisementLocation;
