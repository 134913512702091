import { useEffect, useState } from 'react';

export const licenseToLessonCode = (
  면허보유여부: boolean,
  license: LicenseCode | 'SPECIAL',
): LessonCode => {
  if (면허보유여부) {
    if (license === 'SPECIAL') {
      return 'ONE_LARGE';
    }

    return 'TRAINING_10_HOURS';
  } else {
    if (license === 'ONE_MANUAL') {
      return 'ONE_MANUAL_NEW';
    }

    if (license === 'ONE_AUTO') {
      return 'ONE_AUTO_NEW';
    }

    if (license === 'SPECIAL') {
      return 'ONE_LARGE';
    }

    if (license === 'ONE_LARGE') {
      return 'ONE_LARGE';
    }

    if (license === 'TWO_SMALL') {
      return 'TWO_SMALL_NEW';
    }

    return 'TWO_AUTO_NEW';
  }
};

export const useGetLicenseByFunnel = () => {
  const [lessonType, setLessonType] = useState<LessonCode | null>(null);

  useEffect(() => {
    const userFunnelStorageInfo = localStorage.getItem('user-info');

    if (userFunnelStorageInfo) {
      const userFunnelInfo = JSON.parse(userFunnelStorageInfo);

      const 면허보유여부 = ['보유', 'training'].includes(userFunnelInfo.registerType);
      const licenseType = userFunnelInfo.targetLicense as LicenseCode | 'SPECIAL' | 'UNKNOWN';

      setLessonType(
        licenseToLessonCode(면허보유여부, licenseType === 'UNKNOWN' ? 'TWO_AUTO' : licenseType),
      );
    }
  }, []);

  if (!lessonType) return null;

  return { lessonType };
};
