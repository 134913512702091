import { useEffect, useState } from 'react';

import { navbarPropsAtom } from '@jotaiStore';
import { BottomSheet, NewBoxButton, NewIcon, useOverlay } from 'design-system';
import { useSetAtom } from 'jotai';

import { CertificatePhoneNum } from '@components';
import { NotificationTerm } from '@templates/Terms';

// import { Timer } from '@templates';
import { useEmit } from '@hooks/use-emit';

export const Certification = () => {
  const [isNotificationAgreed, setIsNotificationAgreed] = useState(true);
  const [phoneNum, setPhoneNum] = useState('');
  const overlay = useOverlay();

  const { emitWithValue } = useEmit('login');

  const setNavbarProps = useSetAtom(navbarPropsAtom);

  const [isCertified, setIsCertified] = useState(false);

  useEffect(() => {
    setNavbarProps({ leftItem: 'Back' });
  }, []);

  const canSignUp = phoneNum !== '';

  return (
    <div className="px-16 pb-[20px] pt-20">
      <div className="text-Header mb-20">추가 정보를 입력해주세요!</div>
      <CertificatePhoneNum
        isCertified={isCertified}
        setIsCertified={setIsCertified}
        onSuccess={(phoneNum) => {
          setPhoneNum(phoneNum);
        }}
      />
      <div className="fixed bottom-16 left-0 w-full bg-white px-16">
        <div className="mb-10 flex items-center justify-between">
          <div
            className="flex items-center"
            onClick={() =>
              setIsNotificationAgreed((prev) => {
                return !prev;
              })
            }
          >
            {isNotificationAgreed ? (
              <NewIcon icon="chekincircle-filled" size={24} className="fill-new-gray-900" />
            ) : (
              <NewIcon icon="chekincircle-filled" size={24} className="fill-new-gray-400" />
            )}
            <div className="text-Body3 ml-12">[선택] 광고성 정보 수신동의</div>
          </div>
          <div
            className="text-Body4 mr-20 cursor-pointer text-gray-600 underline"
            onClick={() => {
              overlay.open(({ isOpen, close }) => (
                <BottomSheet
                  isOpen={isOpen}
                  onClose={close}
                  header={{
                    title: '광고성 정보 수신동의',
                  }}
                  main={<NotificationTerm />}
                />
              ));
            }}
          >
            보기
          </div>
        </div>
        <NewBoxButton
          styles={'filled-yellow'}
          label="회원가입 하기!"
          disabled={!canSignUp}
          fill
          onClick={() => {
            emitWithValue({
              phoneNum: phoneNum.trim().replace(/[-]/g, ''),
              result: 'success',
              notificationAgreed: isNotificationAgreed,
            });
          }}
        />
      </div>
    </div>
  );
};
