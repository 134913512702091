import { isEmpty } from 'lodash-es';

import { api } from '@apis/hc';

export const getMapAddressData = async (location: LocationType) => {
  const res = await api.search.address.by.coord.$get({
    query: {
      coord: `${location.longitude},${location.latitude}`,
    },
  });
  const data = (await res.json()) as any;

  let mapAddress = '';
  let buildingName = '';
  let fullAddress = '';
  let bubCode = '';

  if (data.status.name === 'ok') {
    const { area1, area2, area3, area4 } = data.results[0].region;
    mapAddress =
      (area1.name ?? '') +
      ' ' +
      (area2.name ?? '') +
      ' ' +
      (area3.name ?? '') +
      ' ' +
      (area4.name ?? '');

    const resultRoadAddr = data.results.find((result: any) => result.name === 'roadaddr');
    const resultJibunAddr = data.results.find((result: any) => result.name === 'addr');
    const legalCode = data.results[0].code.id;

    if (resultRoadAddr) {
      const buildingAddition = resultRoadAddr?.land?.addition0?.value;

      if (buildingAddition) {
        buildingName = buildingAddition;
      }
    }

    if (resultJibunAddr) {
      const { area1, area2, area3, area4 } = resultJibunAddr.region;

      fullAddress =
        (area1.name ?? '') +
        ' ' +
        (area2.name ?? '') +
        ' ' +
        (area3.name ?? '') +
        ' ' +
        (area4.name ?? '') +
        ' ' +
        resultJibunAddr?.land?.number1 +
        (!isEmpty(resultJibunAddr?.land?.number2) ? `-${resultJibunAddr?.land?.number2}` : '');
    } else if (resultRoadAddr) {
      const { area1, area2, area3, area4 } = resultRoadAddr.region;

      fullAddress =
        (area1.name ?? '') +
        ' ' +
        (area2.name ?? '') +
        ' ' +
        (area3.name ?? '') +
        ' ' +
        (area4.name ?? '') +
        resultRoadAddr?.land?.name +
        ' ' +
        resultRoadAddr?.land?.number1;
    }
  } else {
    // 나중에 수정
    mapAddress = '서초구 서초동';
  }

  return { mapAddress, buildingName, fullAddress };
};

export const getMapAddress = async ({
  queryKey,
}: {
  queryKey: [string, LocationType];
}): Promise<Awaited<ReturnType<typeof getMapAddressData>>> => {
  const [, location] = queryKey;

  return await getMapAddressData(location);
};
